.social_block{
    width: 8%;
    text-align: center;
}

.social_block i{
    display: block;
    margin-top: 20px;
    font-size: 20px;
}
.slider_block1{
    width: 40%;
    margin-left: 8%;
    text-align: left !important;
}

.slider_block2{
    margin-left: 50px;
    margin-right: -100px;
    width: 600px;
}
.img-fluid2{
    width: 100%;
    height: 500px;
}
.sl_content{
    text-align: left !important;
}
.social_block a{
    color: #202020;
    transition: 0.2s;
}

@media (max-width:1191px) {
    .social_block{
        width: 0% !important;
        display: none !important;
    }
    .slider_block1{
        margin-left: 4%;
    }
    .slider_block2{
        margin-left: 10px !important;
        margin-right: -2%;
    }
}

@media (max-width:768px) {
    .social_block{
        width: 0% !important;
        display: none !important;
    }
    .slider_block1{
        margin-left: 0%;
        width: 95%;
        margin: auto;
        text-align: center !important;
    }
    .slider_block2{
        margin-left: 10px !important;
        margin-right: 0 !important;
        width: 95% !important;
        margin: auto !important;
    }
    .dd{
        flex-wrap: wrap !important;
    }
    .sl_content{
        text-align: center !important;
    }
}