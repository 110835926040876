.tc ol { counter-reset: item }
.tc li{ display: block }
.tc li:before { content: counters(item, ".") " "; counter-increment: item ; padding-right: 10px;}
.ols{
    margin-top: 20px;
}
.ols li{
    margin-bottom: 15px;
    display: flex;
    font-size: 15px;
    font-weight: normal !important;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}