.footer{
    padding: 80px 10%;
}
.footer_ico{
    color: #fff !important;
    padding: 10px 15px;
    font-size: 20px;
    border-radius: 50%;
    transition: 0.2s;
    margin-right: 30px;
}
.footer_ico:hover{
    background-color: #FED51B;
    color: #202020 !important;
    transition: 0.2s;
}
.other_link{
    justify-content:flex-end;
}
.c_content2{
    border-right: 0.5px solid rgba(255,255,255,0.8);
    color: aliceblue;
}
@media (max-width:768px) {
    .other_link{
        justify-content: center;
    }
    .title2{
        font-size: 45px;
    }
    .title{
        font-size: 35px;
    }
}
