.nav_wrapper {
    padding: 35px 20px;
}

.nav_item {
    margin-right: 20px;
    padding: 10px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #202020;
    font-family: 'Oswald', sans-serif;
}

.nav_item2 {
    
    padding: 10px 10px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    font-family: 'Oswald', sans-serif;
}

.nav_number {
    color: #202020;
    opacity: .3;
    font-weight: 300;
    font-size: 32px;
}

.nav_number2 {
    color: #fff;
    opacity: .3;
    font-weight: 300;
    font-size: 36px;
}

.menu_icon {
    font-size: 30px;
    margin-right: 30px;
    transition: 0.2s;
}
.menu_icon:hover{
    color: #FED51B;
    transition: 0.3s;
}

.not_header {
    display: none !important;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

.fixed_header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    animation: slideDown 0.40s ease-out;
}

.fixed_header .nav_wrapper {
    padding: 10px 10px;
}

.top_ico {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 40px;
    margin-bottom: 30px;
    padding: 12px 15px;
    background-color: #FED51B;
    border-radius: 50%;
    color: #fff;
    transition: 0.3s;
}

.top_ico:hover {
    background-color: #202020;
    transition: 0.3s;
}
.closeBtn{
    color: #fff;
    font-size: 40px;
}

.offcanvas{
    /* background: rgba(247, 28, 82, 0.6) !important; */
    background: rgba(35, 26, 25, 0.9) !important;
    padding: 50px 80px;
}

.nav_item:hover,.nav_item2:hover{
    color: #FED51B;
    transition: 0.3s;
}

.offcanvas.offcanvas-bottom{
    height: 100vh !important;
}
.blocksidenav{
    width: 48%;
}

.sidenav_content{
    color: #fff;
    font-size: 15px;
    margin-bottom: 10px;
}

@media (max-width:991px) {
    .nav_hide {
        display: none !important;
    }
}
@media (max-width:768px) {
    .nav_wrapper {
        padding: 25px 15px;
    }
    .offcanvas{
        padding: 40px 20px !important;
    }
    .blocksidenav{
        width: 95%;
        margin: auto;
        text-align: center;
        margin-bottom: 40px;
    }
    .footer_ico{
        margin-right: 10px;
    }
    .blocksidenav.blo.ff{
        margin: auto !important;
    }
}